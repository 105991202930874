<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div align="center">
          <img width="150" height="150" src="ovec.png" />
          <br />
          <div class="head">
            เอกสารประกอบการพิจารณาคำร้องขอย้าย
          </div>
          <div class="head">
            ข้าราชการครูและบุคลากรทางการศึกษา
          </div>
          <div class="head">
            ตำแหน่ง ครู สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          </div>

          <div class="head pa-1">
            ครั้งที่
            {{
              transference_personnels.time_ss +
                "/" +
                transference_personnels.year_ss
            }}
       
          </div>
          <div class="text_j text-center font-weight-bold">
           
            เอกสารชุดที่ 1 เอกสารประกอบการพิจารณาการย้ายองค์ประกอบและตัวชี้วัดการพิจารณาการย้าย <br> ขององค์ประกอบที่ 1-6
          </div>
          <br />
          <div class="pa-5">
            <img
              v-if="transference_personnels.personnel_temporary_pic"
              width="300"
              height="300"
              style="border-radius: 8px"
              :src="
                '/HRvecfiles/' + transference_personnels.personnel_temporary_pic
              "
            />
            <img
              v-else
              width="200"
              src="/HRvecfiles/blank-human-image.png"
              style="border-radius: 30px"
            />
          </div>
          <div class="head">
            ชื่อ
            {{
              transference_personnels.title_s +
                transference_personnels.frist_name
            }}
            นามสกุล
            {{ transference_personnels.last_name }}
          </div>
          <div class="head">
            เลขที่ตำแหน่ง {{ transference_personnels.id_position }}
          </div>
          <div class="head">
            วิทยฐานะ {{ transference_personnels.rang_name || "-" }}
          </div>

          <br />
          <br />
          <div class="head">
            {{ transference_personnels.college_name }} จังหวัด{{
              transference_personnels.province_name
            }}
          </div>

          <div v-if="transference_personnels.collegeVei !== '0'">
            <div class="head">
              สถาบันอาชีวศึกษา{{ transference_personnels.veiVocalName }}
            </div>
          </div>
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="bold16" align="center">
          ข้อมูลและเอกสารประกอบตัวชี้วัดตามองค์ประกอบการพิจารณาย้าย
        </div>

        <div class="bold16">
          องค์ประกอบ/ตัวชี้วัดที่ 1 เหตุผลในการขอย้าย
        </div>
        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              เหตุผลการขอย้าย
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 text_al table">
              ( <span v-if="transference_personnels.reason_2 === '1'">*</span> )
              ดูแลบิดา มารดา<br />
              ( <span v-if="transference_personnels.reason_1 === '1'">*</span> )
              อยู่รวมกับคู่สมรสและหรือบุตร <br />
              ( <span v-if="transference_personnels.reason_3 === '1'">*</span> )
              กลับภูมิลำเนา <br />
              ( <span v-if="transference_personnels.reason_4 === '1'">*</span> )
              เหตุผลอื่นๆ <br />
            </td>
            <td class="pa-1 text_al table">
              สำเนาเอกสาร ภาคผนวก หน้า..................
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span>
              เอกสารหลักฐานที่สอดคล้องกับเหตุผลการขอย้าย ทั้งนี้
              ให้ผู้ประสงค์ขอย้าย ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
              <br>
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข
            </td>
          </tr>
        </table>



        <br>
        <div class="bold16">
          องค์ประกอบ/ตัวชี้วัดที่ 2 ผลการปฏิบัติงานที่เกิดกับผู้เรียนและสถานศึกษาปัจจุบัน
        </div>
        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              ผลงานหรือรางวัลเกิดจากการปฏิบัติงานในหน้าที่ที่เกิดขึ้นกับผู้เรียนและสถานศึกษาในระดับสูงสุด จำนวน 1 ผลงาน/รางวัล
            </td>
            <td width="20%" class="bold16 table" align="center">ระดับ</td>
            <td width="30%" class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 text_al table">
             {{ personnel_award_id.performance_name }}
            </td>
            <td class="pa-1 text_al table text-center">
              <span v-if="personnel_award_id.award_level == 'college'">ระดับสถานศึกษา</span>
                <span v-else-if="personnel_award_id.award_level == 'provincial'"
                  >ระดับจังหวัด</span
                >
                <span v-else-if="personnel_award_id.award_level == 'sector'">ระดับภาค</span>
                <span v-else-if="personnel_award_id.award_level == 'vocational'"
                  >ระดับชาติ</span
                >
                <span v-else-if="personnel_award_id.award_level == 'nation'">ระดับชาติ</span>
                <span v-else-if="personnel_award_id.award_level == 'word'">ระดับชาติ</span>
            </td>
            <td class="pa-1 text_al table">
              สำเนาเอกสาร ภาคผนวก หน้า..................
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span>
              โดยแนบเอกสารหลักฐาน โล่รางวัล/ประกาศนียบัตร ซึ่งแสดงให้เห็นถึงผลงานหรือรางวัลที่เกิดจากการปฏิบัติงานในหน้าที่ที่เกิดขึ้นกับผู้เรียนและสถานศึกษาในระดับสูงสุด 1 ผลงาน/รางวัล ย้อนหลังไม่เกิน 3 ปี นับถึงวันสุดท้ายของการยื่นคำร้องขอย้ายประจำปีทั้งนี้ โดยให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษา หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ ในหน้าหน้าคำสั่ง หน้าที่มีชื่อผู้ยื่นย้าย และ หน้าสุดท้ายของคำสั่งที่มีลายมือชื่อของผู้ลงนามในคำสั่ง
              <br>
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข

            </td>
          </tr>
        </table>


             <!-- New Page -->
             <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->


        <div class="bold16 text_j">
          องค์ประกอบ/ตัวชี้วัดที่ 3
          สภาพความยากลำบากในการปฏิบัติงานในสถานศึกษาปัจจุบัน
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              สถานศึกษาปัจจุบัน
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 table">
              {{ transference_personnels.college_name }}<br />
              อำเภอ {{ transference_personnels.prefecture_name }} จังหวัด
              {{ transference_personnels.province_name }} <br />
              (
              <span
                v-if="
                  transference_personnels.province_difficulty === '1' ||
                    transference_personnels.prefecture_difficulty === '1'
                "
                >*</span
              >
              ) สถานศึกษาที่ตั้งอยู่ในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
              เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา
              (อ.จะนะ อ.เทพา อ.นาทวี อ.สะบ้าย้อย)
              /ตั้งอยู่ในพื้นที่พิเศษตามประกาศกระทรวงการคลัง <br />
              (
              <span
                v-if="
                  transference_personnels.province_difficulty === '0' ||
                    transference_personnels.prefecture_difficulty === '0'
                "
                >*</span
              >
              ) สถานศึกษาที่ตั้งอยู่ในพื้นที่ปกติ
            </td>
            <td class="pa-1 table text_al">
              รายชื่อสถานศึกษาตามประกาศพื้นที่พิเศษของกระทรวงการคลังที่ประกาศใช้อยู่ในวันสุดท้ายที่ยื่นคำร้องขอย้ายประจำปี
              (สามารถติดตามและ ดาวน์โหลดได้จากเว็บไซต์ (www.cgd.go.th)
            </td>
          </tr>
          <tr>
            <td colspan="4" class="pa-1 table text_al">
              <span class="bold16">หมายเหตุ</span>
              ผู้ที่ขอย้ายเข้าหรือย้ายออกจากสถานศึกษาที่ตั้งอยู่ในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
              เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา
              (อ.จะนะ อ.เทพา อ.นาทวี อ.สะบ้าย้อย) <br>
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข
            </td>
          </tr>
        </table>



        <div class="bold16">
          องค์ประกอบ/ตัวชี้วัดที่ 4
          ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน 
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              ประสบการณ์สอนในสถานศึกษาปัจจุบัน
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 table">
              อายุงาน ณ สถานศึกษาปัจจุบัน :
              {{ transference_personnels.age_app_time }} <br />

              <!-- {{ age_appCal }} เดือน <br />
 -->
              ( <span v-if="age_appCal >= 60">*</span> ) 60 เดือนขึ้นไป <br />
              ( <span v-if="age_appCal >= 48 && age_appCal <=59">*</span> ) 48 - 59 เดือน <br />

              ( <span v-if="age_appCal >= 36 && age_appCal <= 47">*</span> ) 36
              - 47 เดือน <br />
              ( <span v-if="age_appCal >= 24 && age_appCal <= 35">*</span> ) 24
              - 35 เดือน <br />
            </td>
            <td class="pa-1 table text_al">
              สำเนาก.พ.7 /ก.ค.ศ.16 <br />
              ภาคผนวก หน้า.................
            </td>
          </tr>
          <tr>
            <td colspan="4" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span>
              ที่ได้รับบรรจุแต่งตั้งในสถานศึกษาปัจจุบันนับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย
สำเนา ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน โดยให้ผู้อำนวยการสถานศึกษา หัวหน้าส่วนราชการ 
หรือผู้ที่ได้รับมอบหมายงานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้องตามระเบียบงานสารบรรณ 
ทั้งนี้ ระยะเวลาเกิน 15 วัน ให้นับเป็น 1 เดือน (หลักเกณฑ์กำหนดให้จัดการเรียนการสอนในสถานศึกษาปัจจุบัน ติดต่อกันมาแล้วไม่น้อยกว่า 24 เดือน นับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้องขอย้าย)
<br>
แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข

            </td>
          </tr>
        </table>


                        <!-- New Page -->
              <p style="page-break-before: always;">&nbsp;</p>
        <!-- 9 -->
        <div class="bold16">
          องค์ประกอบ/ตัวชี้วัดที่ 5 การรักษาวินัย คุณธรรม จริยธรรม
          และจรรยาบรรณวิชาชีพ
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              การรักษาวินัยและจรรยาบรรณวิชาชีพ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 table text_al">
              (......) ได้รับการยกย่อง เชิดชูเกียรติ ด้านวินัย และจริยธรรม
              และไม่เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ <br />
              (......) ไม่เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ <br />
              (......) เคยถูกลงโทษทางวินัย หรือทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="pa-1 table text_al">
              สำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน ย้อนหลัง 3 ปี
              นับถึงวันแรกของกำหนดการยื่นคำร้องขอย้ายประจำปี
              โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองความถูกต้อง
              ทั้งนี้ ผู้ใดได้รับการล้างมลทิน ตาม พ.ร.บ.ล้างมลทิน
              ให้ถือว่าเป็นผู้เคยถูกลงโทษทางวินัยในระดับโทษนั้นๆ
              <br />
              ภาคผนวก ข หน้า.....................
            </td>
          </tr>
          <tr>
            <td colspan="4" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span> สำเนา ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน โดยให้ผู้อำนวยการสถานศึกษา หัวหน้าส่วนราชการ 
หรือผู้ที่ได้รับมอบหมายงานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้องตามระเบียบงานสารบรรณ 
<br>
แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข

            </td>
          </tr>
        </table>


        <br>
        <div class="bold16 text_j">
          องค์ประกอบ/ตัวชี้วัดที่ 6 อายุราชการ
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              อายุราชการ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 table text_al">
              อายุราชการ : {{ transference_personnels.age_time }}
              <br />
              ( <span v-if="age_time_result >= 10"> * </span> ) ตั้งแต่ 10
              ปีขึ้นไป <br />
        
              ( <span v-if="age_time_result >= 8 && age_time_result <=9 "> * </span> ) ระหว่าง 8 - 9 ปี<br />
              ( <span v-if="age_time_result >= 6 && age_time_result <=7 "> * </span> ) ระหว่าง 6 - 7 ปี<br />
              ( <span v-if="age_time_result >= 4 && age_time_result <=5 "> * </span> ) ระหว่าง 4 - 5 ปี<br />
              ( <span v-if="age_time_result < 4"> * </span> ) ต่ำกว่า 4 ปี
            </td>
            <td class="pa-1 table text_al">
              สำเนา ก.พ.7 / ก.ค.ศ. 16 <br />
              ภาคผนวก หน้า................. <br />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span> สำเนา ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน โดยให้ผู้อำนวยการสถานศึกษา หัวหน้าส่วนราชการ 
หรือผู้ที่ได้รับมอบหมายงานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้องตามระเบียบงานสารบรรณ 
ทั้งนี้ หากมีเศษของปี ตั้งแต่ 6 เดือนขึ้นไปนับเป็น 1 ปี    <br>
แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ข

            </td>
          </tr>
        </table>


      
        <br />
        <table width="100%" align="center" class="text_j">
          <tr>
            <td width="50%"></td>
            <td align="center" width="50%">
              <div class="regular16">
                <span class="regular16"
                  >ขอรับรองว่าข้อมูลข้างต้นทั้งหมดเป็นความจริง</span
                >
                <br />
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...................................................ผู้ขอย้าย</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                (
                {{
                  transference_personnels.title_s +
                    transference_personnels.frist_name
                }}
                {{ transference_personnels.last_name }} )
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง {{ transference_personnels.position_name }}</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <br />
              <br />
            </td>
            <td></td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...............................................................</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                (....................................................................)
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง..............................................................</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16">
                  วันที่
                  {{
                    date_today
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}</span
                >
              </div>
            </td>
          </tr>
        </table>

   
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    periods: [],
    transference_personnels: [],
    personnel_educations: [],
    personnel_dutys: [],
    personnel_awards: [],
    personnel_experiences: [],
    personnel_experiencePros: [],
    personnel_award_id: [],

  }),

  async mounted() {
    await this.periodQuery();
    await this.transference_personnelsQueryAll();
    await this.personnel_educationsQueryAll();
    await this.personnel_awardsQueryAll();
    await this.personnel_dutysQueryAll();
    await this.personnel_experiencesQueryAll();
    await this.personnel_experiencesProQueryAll();
    await this.transference_personnels_award();
  },

  methods: {
    async transference_personnels_award() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        id_pa: this.transference_personnels.tp_award
      });
      this.personnel_award_id = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periods = result_period.data;
    },

    async transference_personnelsQueryAll() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: this.url_result
      });
      this.transference_personnels = result.data;
    },

    async personnel_educationsQueryAll() {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_personnels.id_card
      });
      this.personnel_educations = result.data;
    },
    /* professional */
    async personnel_dutysQueryAll() {
      let result = await this.$http.post("personnel_duty.php", {
        ApiKey: this.ApiKey,
        personnel_dutyIDCard: this.transference_personnels.id_card
      });
      this.personnel_dutys = result.data;
    },

    async personnel_awardsQueryAll() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        personnel_awardid_card: this.transference_personnels.id_card,
        type_performanceCk: "Ok"
      });
      this.personnel_awards = result.data;
    },

    async personnel_experiencesQueryAll() {
      let result = await this.$http.post("personnel_experience.php", {
        ApiKey: this.ApiKey,
        pexperienceid_card: this.transference_personnels.id_card,
        pexperienceType: "self"
      });
      this.personnel_experiences = result.data;
    },

    async personnel_experiencesProQueryAll() {
      let result = await this.$http.post("personnel_experience.php", {
        ApiKey: this.ApiKey,
        pexperienceid_card: this.transference_personnels.id_card,
        pexperienceType: "professional"
      });
      this.personnel_experiencePros = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    /* อายุราชการในสาขาวิชาเอกที่ย้ายไปดำรงตำแหน่ง */
    age_position_year() {
      let age = this.transference_personnels.age_time;
      let split_year = age.split(" ");
      return split_year[0];
    },
    age_position_month() {
      let age = this.transference_personnels.age_time;
      let split_year = age.split(" ");
      let result;
      if (split_year >= 6) result = 1;
      else result = 0;
      return result;
    },
    age_time_result() {
      let month = this.age_position_month;
      let year = this.age_position_year;
      let result = parseInt(year) + parseInt(month);
      return result;
    },
    /* ประสบการณ์สอนในสถานศึกษาปัจจุบัน */

    age_appnow_year() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      let result = parseInt(split_year[0] * 12);
      return result;
    },
    age_appnow_month() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      return split_year[2];
    },
    age_appnow_day() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      return split_year[4];
    },
    age_appCal() {
      let yearMonth = this.age_appnow_year;
      let month = this.age_appnow_month;
      let day = this.age_appnow_day;
      let result;
      if (day >= 15) 
      {
        result = parseInt(yearMonth) + parseInt(month) + 1;
      }
      else
      {
         result = parseInt(yearMonth)+parseInt(month);
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

.text_al {
  text-align: left;
  vertical-align: top;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}
#p1 {
  text-indent: 4em;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
        height: 247mm;
      }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
          font-size: 18px;
          font-weight: bold;
        }
        .regular12 {
          font-size: 12px;
        }
        .blod12 {
          font-size: 12px;
          font-weight: bold;
        }
        .blackRegula8 {
          font-size: 8px;
        } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
